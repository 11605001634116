import React, { createContext, useState, useEffect, useContext } from 'react';
import publicIp from 'public-ip';

import { trackRegistration, getIsFacebook } from '../services/affiliate';
import { getFirebase } from '../services/firebase';
import { User } from '../types';

const REGISTERED_EMAIL_KEY = 'registeredEmail';
const REGISTERED_FIRSTNAME_KEY = 'registeredFirstName';

interface UserContextProps {
  user: User;
  firebase: object;
  selectedPlan?: object;
  getFeaturedGiftDownloadLink: Function;
  updateDb: Function;
  register: Function;
  hasPaidForProduct: (productDescription: string) => boolean;
  signOut: Function;
  signIn: Function;
  signUp: Function;
  resetPassword: Function;
  setPlan: Function;
  createPaypalOrder: Function;
  confirmPaypalOrder: Function;
  createPaidCustomer: Function;
  deletePaidCustomer: Function;
  exportCustomers: Function;
  isPaid: Boolean;
  isRegistered: Boolean;
  isLoggedIn: Boolean;
  isAdmin: Boolean;
}

const defaultContext = {
  user: {},
  updateDb: () => {},
  register: () => {},
  getFeaturedGiftDownloadLink: () => {},
  hasPaidForProduct: () => false,
  isPaid: false,
  isRegistered: false,
  isLoggedIn: false,
  isAdmin: false,
  signOut: () => {},
  signIn: () => {},
  signUp: () => {},
  resetPassword: () => {},
  firebase: {},
  selectedPlan: null,
  setPlan: () => {},
  createPaypalOrder: () => {},
  confirmPaypalOrder: () => {},
  createPaidCustomer: () => {},
  deletePaidCustomer: () => {},
  exportCustomers: () => {},
};
export const UserContext = createContext<UserContextProps>(defaultContext);

export const UserContextProvider = ({ children }) => {
  const firebase = getFirebase();

  const [user, setUser] = useState<User>({});
  const [paypalPaid, setPaypalPaid] = useState([]);
  const [stripePaid, setStripePaid] = useState([]);
  const [selectedPlan, setPlan] = useState(null);

  const getClientIp = () =>
    publicIp.v4({
      fallbackUrls: ['https://ifconfig.co/ip'],
    });

  useEffect(() => {
    const registeredEmail = localStorage.getItem(REGISTERED_EMAIL_KEY);
    if (registeredEmail)
      firebase
        .registration(registeredEmail)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            const user = snapshot.data();
            setUser(user);
          } else {
            console.error('No user reg data found for email', registeredEmail);
            setUser(undefined);
          }
        });
  }, [firebase]);

  useEffect(() => {
    const unregisterAuthObserver = firebase.onAuthUserListener(
      async (authUser) => {
        if (authUser) {
          const userBase = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
          };

          firebase.registration(authUser.email).onSnapshot((snapshot) => {
            if (snapshot.exists) {
              const registrationData = snapshot.data();
              setUser((oldState) => ({
                ...userBase,
                ...oldState,
                ...registrationData,
              }));
            }
          });

          firebase.customer(authUser.uid).onSnapshot((snapshot) => {
            if (snapshot.exists) {
              const customerData = snapshot.data();
              setUser((oldState) => ({
                ...userBase,
                ...oldState,
                ...customerData,
              }));
            }
          });

          firebase
            .customerStripePayments(authUser.uid)
            .where('status', '==', 'succeeded')
            .onSnapshot((snapshot) => {
              const paymentsPaid = [...stripePaid];
              snapshot.forEach((sh) => {
                const payment = sh.data();
                if (payment.status === 'succeeded' && payment.description)
                  paymentsPaid.push(payment.description);
              });

              setStripePaid(paymentsPaid);
            });

          firebase
            .customerPaypalPayments(authUser.uid)
            .where('status', '==', 'succeeded')
            .onSnapshot((snapshot) => {
              const paymentsPaid = [...paypalPaid];
              snapshot.forEach((sh) => {
                const payment = sh.data();
                if (payment.status === 'succeeded' && payment.description) {
                  paymentsPaid.push(payment.description);
                }
              });

              setPaypalPaid(paymentsPaid);
            });

          setUser(userBase);
        }
      },
      () => {
        console.log('Signed Out');
        setUser(undefined);
        setPaypalPaid([]);
        setStripePaid([]);
      }
    );
    return () => unregisterAuthObserver();
  }, []);

  const getFeaturedGiftDownloadLink = () =>
    firebase.getFeaturedGiftDownloadLink();

  const updateUserDb = async (payload) => {
    try {
      await firebase.customer(user.uid).set(
        {
          ...payload,
        },
        { merge: true }
      );
    } catch (e) {
      console.error('Error updating user db', e);
    }
  };

  const registerUserDb = async (payload) => {
    try {
      if (user && user.email) {
        firebase.doSignOut();
      }
      const ip = await getClientIp();
      if (ip) {
        await firebase.registration(payload.email).set(
          {
            ...payload,
            ip,
            userAgent: window.navigator.userAgent,
            isFb: getIsFacebook(),
          },
          { merge: true }
        );

        localStorage.setItem(REGISTERED_EMAIL_KEY, payload.email);
        localStorage.setItem(REGISTERED_FIRSTNAME_KEY, payload.firstName);
        await trackRegistration(payload.email, payload.firstName);
        setUser(payload);
      } else {
        throw Error('Error retrieving IP of user');
      }
    } catch (e) {
      console.error('Error registering user', e);
    }
  };

  const resetPassword = ({ email, continueUrl }) =>
    firebase.doPasswordReset(email, { url: continueUrl });

  const hasPaidForProduct = (productDescription: string) =>
    !!paypalPaid.find((paymentDescription) =>
      paymentDescription
        .toLowerCase()
        .includes(productDescription.toLowerCase())
    ) ||
    !!stripePaid.find((paymentDescription) =>
      paymentDescription
        .toLowerCase()
        .includes(productDescription.toLowerCase())
    );

  const createPaypalOrder = () => {
    const createOrderMethod = firebase.paypalCreateOrder();
    return createOrderMethod({
      product_id: selectedPlan.id,
      shipping:
        selectedPlan.requiresAddress && user.address
          ? {
              address: {
                address_line_1: user.address.line1,
                address_line_2: user.address.line2,
                country_code: user.address.country,
                admin_area_1: user.address.region,
                admin_area_2: user.address.city,
                postal_code: user.address.zip,
              },
            }
          : undefined,
    }).then((response) => response.data.id);
  };

  const confirmPaypalOrder = (orderId) => {
    const confirmOrderMethod = firebase.paypalConfirmOrder();
    return confirmOrderMethod({ orderId });
  };

  const createPaidCustomer = (payload) => {
    const createPaidCustomerMethod = firebase.createPaidCustomer();
    return createPaidCustomerMethod(payload);
  };

  const deletePaidCustomer = (payload) => {
    const deletePaidCustomerMethod = firebase.deletePaidCustomer();
    return deletePaidCustomerMethod(payload);
  };

  const exportCustomers = (payload) => {
    const exportCustomersMethod = firebase.exportCustomers();
    return exportCustomersMethod(payload);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateDb: updateUserDb,
        register: registerUserDb,
        getFeaturedGiftDownloadLink,
        hasPaidForProduct,
        isPaid: stripePaid.length > 0 || paypalPaid.length > 0,
        isRegistered: !!(user && user.email),
        isLoggedIn: !!(user && user.uid),
        isAdmin: user && user.admin,
        signOut: firebase.doSignOut,
        signIn: firebase.doSignInWithEmailAndPassword,
        signUp: firebase.doCreateUserWithEmailAndPassword,
        resetPassword,
        firebase,
        selectedPlan,
        setPlan,
        createPaypalOrder,
        confirmPaypalOrder,
        createPaidCustomer,
        deletePaidCustomer,
        exportCustomers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (typeof window === 'undefined') {
    return defaultContext;
  }

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

export default UserContextProvider;
