import { isPast, format } from 'date-fns';

const formatReplayDateString = (startTime, endTime, days) => {
  const conferenceReplayStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceReplayEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );

  return `Replay weekend ${format(
    conferenceReplayStartTime,
    `MMMM ${days ? 'EEEE' : ''} do`
  )} - ${format(conferenceReplayEndTime, `${days ? 'EEEE' : ''} do yyyy`)}`;
};

const formatOriginalDateString = (startTime, endTime, days) => {
  const conferenceStartTime = new Date(
    startTime.year,
    startTime.month,
    startTime.day,
    startTime.hour
  );
  const conferenceEndTime = new Date(
    endTime.year,
    endTime.month,
    endTime.day,
    endTime.hour
  );

  return `${format(
    conferenceStartTime,
    `MMMM ${days ? 'EEEE' : ''} do`
  )} - ${format(conferenceEndTime, `${days ? 'EEEE' : ''} do yyyy`)}`;
};

const hasConferenceEnded = (endTime) =>
  isPast(new Date(endTime.year, endTime.month, endTime.day, endTime.hour));

export const formatConferenceDateString = (
  startTime,
  endTime,
  replayStartTime,
  replayEndTime,
  days
) =>
  hasConferenceEnded(endTime) && replayStartTime && replayEndTime
    ? formatReplayDateString(replayStartTime, replayEndTime, days)
    : formatOriginalDateString(startTime, endTime, days);
