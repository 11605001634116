/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';

import * as normalize from 'normalize.css';

import { UserContextProvider } from './src/context/UserContext';
import { SiteMetaContextProvider } from './src/context/SiteMetaContext';
import { captureAffiliateIds } from './src/services/affiliate';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
  captureAffiliateIds();
};

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>
    <SiteMetaContextProvider>{element}</SiteMetaContextProvider>
  </UserContextProvider>
);
